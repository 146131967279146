<template>
  <div id="#options">
    <b-btn
      @click="backToDatasets()"
      class="btn-sm mb-2"
    >← Back to datasets</b-btn>
    <h2>Interpolate {{ $store.state.dataset.name }}</h2>
    <p>A new interpolated dataset will be created using a 3 meter grid. Note that any filters applied to the dataset will be active during this process.</p>
    <p
      v-if="$store.state.dataset.headers"
      class="mb-0 mt-4"
    ><strong>Choose value to interpolate by</strong></p>
    <div
      v-for="(header, index) in $store.state.dataset.headers"
      v-bind:key="index"
      class="btn btn-sm btn-primary mt-1 mr-1 clickable"
      @click="chosenHeader = header"
      :class="{'active':(chosenHeader === header)}"
    >
      {{ header }}
    </div>
    <div>
      <b-btn class="mt-3 btn-sm" v-b-toggle.advancedOptions>Advanced Options ></b-btn>
      <b-collapse id="advancedOptions" class="mt-2">
        <b-card>
          <p class="card-text"><strong>Advanced Options</strong></p>
          <b-form-group id="input-group-neighborhoodSetting" label="Neighborhood Based On:" label-for="neighborhoodSetting">
            <b-form-select
              id="neighborhoodSetting"
              v-model="neighborhoodSetting"
              :options="[{ text: 'Fixed Distance', value: 'distance' }, { text: 'Number of Nearest Neighbors', value: 'nearest' }]"
              required
            ></b-form-select>
          </b-form-group>
          <b-form-group
            id="input-group-distance"
            label="Neighborhood Distance in Meters:"
            label-for="distance"
            v-if="neighborhoodSetting === 'distance'"
          >
            <b-form-select
              id="distance"
              v-model="neighborhoodDistance"
              :options="[{ text: '8 Meters', value: 0.008 }, { text: '16 Meters', value: 0.016 }]"
            ></b-form-select>
          </b-form-group>
          <b-form-group
            id="input-group-nearest"
            label="Number of Nearest Neighbor Points:"
            label-for="nearest"
            v-if="neighborhoodSetting === 'nearest'"
          >
            <b-form-select
              id="nearest"
              v-model="neighborhoodMaxPoints"
              :options="[{ text: '20 Neighbors', value: 20 }, { text: '50 Neighbors', value: 50 }, { text: '100 Neighbors', value: 100 }]"
            ></b-form-select>
          </b-form-group>
        </b-card>
      </b-collapse>
    </div>
    <b-btn
      v-if="chosenHeader"
      class="w-100 mt-4 btn-lg btn-success"
      @click="interpolate()"
    >Interpolate</b-btn>
  </div><!-- /#options -->
</template>

<script>
const fb = require('../firebaseConfig.js')

export default {
  data () {
    return {
      chosenHeader: false,
      neighborhoodSetting: 'distance',
      neighborhoodDistance: 0.016,
      neighborhoodMaxPoints: 20
    }
  },
  methods: {
    backToDatasets () {
      this.$store.commit('setSpinner', false)
      this.$store.commit('showOptions', false)
    },
    interpolate () {
      const dataset = this.$store.state.dataset
      dataset.createdOn = new Date()
      dataset.interpolated = false
      dataset.chosenHeader = this.chosenHeader
      dataset.neighborhoodSetting = this.neighborhoodSetting
      dataset.neighborhoodDistance = this.neighborhoodDistance
      dataset.neighborhoodMaxPoints = this.neighborhoodMaxPoints
      fb.interpolationsCollection.add(dataset).then(doc => {
        this.$store.commit('showOptions', false)
        this.$store.commit('showDownload', true)
        // this.$store.dispatch('alert', 'Interpolation initiated. This new dataset will appear in your drawer shortly.')
        fb.interpolationsCollection.doc(doc.id).onSnapshot(snapshot => {
          const download = snapshot.data()
          download.id = snapshot.id
          download.name = 'Interpolated_' + download.name
          this.$store.commit('setDownload', download)
          if (download.interpolated) {
            this.$store.commit('setSpinner', false)
          }
        })
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
