import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    alert: false,
    breadcrumbs: [
      {
        id: 'root',
        name: 'Root'
      }
    ],
    datasets: [],
    dataset: {},
    download: {},
    farm: {},
    folders: [],
    folder: {
      id: 'root',
      name: 'Root'
    },
    limit: 10,
    setLoadingMore: false,
    spinner: false,
    showOptions: false,
    showDownload: false
  },
  mutations: {
    setStateProperty (state, options) {
      state[options.property] = options.value
    },
    setLimit (state, limit) {
      state.limit = limit
    },
    setLoadingMore (state, boolean) {
      state.setLoadingMore = boolean
    },
    setSpinner (state, boolean) {
      state.spinner = boolean
    },
    setDatasets (state, datasets) {
      state.datasets = datasets
    },
    setDataset (state, dataset) {
      state.dataset = dataset
    },
    setDownload (state, dataset) {
      state.download = dataset
    },
    showOptions (state, boolean) {
      state.showOptions = boolean
    },
    showDownload (state, boolean) {
      state.showDownload = boolean
    },
    setAlert (state, message) {
      state.alert = message
    }
  },
  actions: {
    alert ({ commit, state }, message) {
      setTimeout(() => {
        commit('setAlert', message)
      }, 100)
      setTimeout(() => {
        commit('setAlert', false)
      }, 8000)
    },
    initPlugin ({ commit, state, dispatch }, farmId) {
      commit('setStateProperty', { property: 'spinner', value: true })
      const timeStamp = new Date().getTime()
      const url = 'https://api.efficientvineyard.com/farm/' + farmId + '?timestamp=' + timeStamp
      axios.get(url).then(response => {
        response.data.id = farmId
        commit('setStateProperty', { property: 'farm', value: response.data })
        commit('setStateProperty', { property: 'spinner', value: false })
        return true
      })
    }
    // fetchDatasets ({ commit, state }, farmId) {
    //   commit('setSpinner', true)
    //   const timeStamp = new Date().getTime()
    //   const url = 'https://api.efficientvineyard.com/datasets/' + farmId + '?limit=' + state.limit + '&timestamp=' + timeStamp
    //   axios.get(url).then(response => {
    //     const responseDatasets = []
    //     response.data.forEach(item => {
    //       const datasetObj = item
    //       datasetObj.selected = false
    //       responseDatasets.push(datasetObj)
    //     })
    //     commit('setDatasets', responseDatasets)
    //     commit('setSpinner', false)
    //     return true
    //   })
    // },
    // fetchMoreDatasets ({ commit, state }, farmId) {
    //   commit('setLoadingMore', true)
    //   const timeStamp = new Date().getTime()
    //   const url = 'https://api.efficientvineyard.com/datasets/' + farmId + '?limit=' + state.limit + '&timestamp=' + timeStamp
    //   axios.get(url).then(response => {
    //     const responseDatasets = []
    //     response.data.forEach(item => {
    //       const datasetObj = item
    //       datasetObj.selected = false
    //       responseDatasets.push(datasetObj)
    //     })
    //     commit('setDatasets', responseDatasets)
    //     commit('setLoadingMore', false)
    //     return true
    //   })
    // }
  },
  modules: {
  }
})
