<template>
  <div id="home">
    <div class="container mt-4">
      <DatasetPicker v-if="!$store.state.showOptions && !$store.state.showDownload && $store.state.farm.id" />
      <Options v-if="$store.state.showOptions" />
      <Download v-if="$store.state.showDownload" />
    </div><!-- /.container -->
  </div><!-- /#home -->
</template>

<script>
import Options from './Options'
import DatasetPicker from './DatasetPicker'
import Download from './Download'

export default {
  components: {
    Options,
    DatasetPicker,
    Download
  },
  created () {
    this.$store.dispatch('initPlugin', this.$route.params.farmId)
  }
}
</script>
